import { ControllerFlowAPI, IWixAPI } from '@wix/yoshi-flow-editor';



export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const getMembersAPI = (wixCodeApi: IWixAPI) => wixCodeApi.site.getPublicAPI(MEMBERS_APP_DEF_ID);
interface PublicRouterData {
  viewedUser?: { id: string };
}

export const getViewedMemberId = (flowApi: ControllerFlowAPI) => {
  const { window, user } = flowApi.controllerConfig.wixCodeApi;

  const routerData = window.getRouterPublicData<PublicRouterData>();

  const currentMemberId = user.currentUser.loggedIn
    ? user.currentUser.id
    : null;

  // Fallback to currentMemberId required for ADI since getRouterPublicData is unavaible
  return routerData?.viewedUser?.id ?? currentMemberId;
};


export const getViewedUserId = async (wixCodeApi: IWixAPI) => {
  const { window, user } = wixCodeApi;
  const currentMemberId = user.currentUser.loggedIn
    ? user.currentUser.id
    : null;
  try {
    const membersApi = await getMembersAPI(wixCodeApi)
    const viewedUserId = await membersApi.getViewedUser();
    return viewedUserId ?? currentMemberId;
  } catch (e) {
    return currentMemberId;
  }
}
