import {
  ControllerParams,
  CreateControllerFn,
  IWixAPI,
} from '@wix/yoshi-flow-editor';
import { getViewedUserId } from './get-viewer-member-id';
import { createEventHandler } from '@wix/tpa-settings';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}: ControllerParams) => {
  const { platformAPIs, config, setProps } = controllerConfig;
  const componentEventHandler = createEventHandler<{ settingsUpdated: any }>(
    config.publicData.APP || {},
  );
  return {
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
    async pageReady(...args) {
      componentEventHandler.on('settingsUpdated', (settings: any) => {
        setProps({ newSettings: settings });
    });
      const wixCodeApi: IWixAPI = controllerConfig.wixCodeApi;
      const isEditor = !flowAPI?.environment?.isViewer;
      const isPreview = flowAPI.environment.isPreview;
      const biToken = platformAPIs?.bi?.biToken;
      const navigateToPricingPlansApp = async (pricingPlansIds: any[]) => {
        const { relativeUrl }: any = await wixCodeApi?.site?.getSectionUrl({
          sectionId: 'membership_plan_picker_tpa',
          appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
        });
        wixCodeApi.location.to &&
          wixCodeApi?.location.to(
            relativeUrl +
              '?appSectionParams=' +
              JSON.stringify({ planIds: pricingPlansIds.join(',') }),
          );
      };
      const navigateToLibrary = async () => {
        const { relativeUrl }: any = await wixCodeApi?.site?.getSectionUrl({
          sectionId: 'wix_file_share_page',
          appDefinitionId: '1537b24e-29d1-6d8f-b8e1-d6860f2f70b9',
        });

        wixCodeApi.location.to && wixCodeApi?.location.to(relativeUrl);
      };
      const tempBaseUrl = wixCodeApi.location.baseUrl;
      let baseUrl;
      const isEditor_X =
        controllerConfig?.config?.style?.styleParams?.booleans?.responsive ??
        false;
      if (tempBaseUrl.includes('editor.wix')) {
        baseUrl = '';
      } else if (tempBaseUrl.includes('editorx')) {
        baseUrl = '';
      } else if (tempBaseUrl.includes('wixsite.com/')) {
        baseUrl = tempBaseUrl.substring(0, tempBaseUrl.indexOf('wixsite.com/'));
        baseUrl = baseUrl + 'wixsite.com/';
      } else {
        baseUrl = wixCodeApi.location.baseUrl;
      }
      const instance = controllerConfig.appParams.instance;
      const { appParams, compId } = controllerConfig;
      const user: any = {};

      const requestLogin = wixCodeApi.user.promptLogin;

      if (args[1]?.user?.currentUser.loggedIn) {
        try {
          user.instance = args[1]?.user?.currentUser?.instance;
        } catch (error) {
          console.error(error);
        }
      }
      user.id = args[1]?.user?.currentUser?.id;
      user.isLoggedIn = args[1]?.user?.currentUser?.loggedIn;
      user.role = args[1]?.user?.currentUser?.role;

      wixCodeApi?.user?.onLogin((userData: any) => {
        setTimeout(async () => {
          const signedInstance =
            wixCodeApi?.site?.getAppToken &&
            wixCodeApi?.site?.getAppToken(
              controllerConfig.appParams.appDefinitionId,
            );
          const loggedInUser: any = {};
          loggedInUser.id = userData?.id;
          loggedInUser.isLoggedIn = userData?.loggedIn;
          loggedInUser.role = userData?.role;
          controllerConfig.setProps({
            appParams,
            compId,
            user: loggedInUser,
            instance: signedInstance,
            biToken,
            fitToContentHeight: true,
            isEditor,
          });
        });
      });

      setProps({
        newSettings: {},
        isMembersArea: true,
        viewedUserId: user?.id,
        getViewedMemberId: async () => {
          const viewedUserId = await getViewedUserId(wixCodeApi);
          controllerConfig.setProps({ viewedUserId });
        },
        isEditor_X,
        user,
        baseUrl,
        instance,
        requestLogin,
        navigateToPricingPlansApp,
        appParams,
        compId,
        biToken,
        fitToContentHeight: true,
        isEditor,
        isPreview,
        appUrls: {
          siteUrl: tempBaseUrl,
          pageUrl: wixCodeApi.site.currentPage?.url,
        },
        navigateToLibrary,
      });
    },
  };
};

export default createController;
